/*
 *  Select2
 *
 *  Version: 3.4.6 Timestamp: Sat Mar 22 22:30:15 EDT 2014
 *
 */

.select2-container {
    margin: 0;
    position: relative;
    display: inline-block;
    /* inline-block for ie7 */
    zoom: 1;
    *display: inline;
    vertical-align: middle;
}

.select2-container .select2-choice {
    display: block;
    height: 34px;
    padding: 0 0 0 8px;
    overflow: hidden;
    position: relative;
    border: 1px solid $color_light_secondary;
    white-space: nowrap;
    line-height: 34px;
    color: $color_dark;
    text-decoration: none;
    border-radius: 4px;
    background-clip: padding-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #ffffff;
}

.select2-container.select2-drop-above .select2-choice {
    border-bottom-color: #aaa;
    border-radius: 0 0 4px 4px;
}

.select2-container.select2-allowclear .select2-choice .select2-chosen {
    margin-right: 42px;
}

.select2-container .select2-choice > .select2-chosen {
    margin-right: 26px;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    float: none;
    width: auto;
}

.select2-container .select2-choice abbr {
    display: none;
    width: 12px;
    height: 12px;
    position: absolute;
    right: 24px;
    top: 8px;
    font-size: 1px;
    text-decoration: none;
    border: 0;
    background: url('#{$assets_folder}/jquery.select2/select2.png') right top no-repeat;
    cursor: pointer;
    outline: 0;
}

.select2-container.select2-allowclear .select2-choice abbr {
    display: inline-block;
}

.select2-container .select2-choice abbr:hover {
    background-position: right -11px;
    cursor: pointer;
}

.select2-drop-mask {
    border: 0;
    margin: 0;
    padding: 0;
    position: fixed;
    left: 0;
    top: 0;
    min-height: 100%;
    min-width: 100%;
    height: auto;
    width: auto;
    opacity: 0;
    z-index: 9998;
    /* styles required for IE to work */
    background-color: #fff;
    filter: alpha(opacity=0);
}

.select2-drop {
    width: 100%;
    margin-top: -1px;
    position: absolute;
    z-index: 9999;
    top: 100%;
    background: #fff;
    color: #000;
    border: 1px solid #aaa;
    border-top: 0;
    border-radius: 0 0 4px 4px;
}

.select2-drop.select2-drop-above {
    margin-top: 1px;
    border-top: 1px solid #aaa;
    border-bottom: 0;
    border-radius: 4px 4px 0 0;
}

.select2-drop-active {
    border: 1px solid $color_highlight;
    border-top: none;
}

.select2-drop.select2-drop-above.select2-drop-active {
    border-top: 1px solid $color_highlight;
}

.select2-drop-auto-width {
    border-top: 1px solid #aaa;
    width: auto;
}

.select2-drop-auto-width .select2-search {
    padding-top: 4px;
}

.select2-container .select2-choice .select2-arrow {
    display: inline-block;
    width: 18px;
    height: 100%;
    position: absolute;
    right: 2px;
    top: 2px;
    background-clip: padding-box;
    background: #ffffff;
}

.select2-container .select2-choice .select2-arrow b {
    display: block;
    width: 100%;
    height: 100%;
    background: url('#{$assets_folder}/jquery.select2/select2.png') no-repeat 0 1px;
}

.select2-search {
    display: inline-block;
    width: 100%;
    min-height: 26px;
    margin: 0;
    padding-left: 4px;
    padding-right: 4px;
    position: relative;
    z-index: 10000;
    white-space: nowrap;
}

.select2-search input {
    width: 100%;
    height: auto !important;
    min-height: 26px;
    padding: 4px 20px 4px 5px;
    margin: 0;
    outline: 0;
    font-family: sans-serif;
    font-size: 1em;
    border: 1px solid $color_light_secondary;
    border-radius: 4px;
    -webkit-box-shadow: none;
    box-shadow: none;
    background: #ffffff url('#{$assets_folder}/jquery.select2/select2.png') no-repeat 100% -22px;
}

.select2-drop.select2-drop-above .select2-search input {
    margin-top: 4px;
}

.select2-search input.select2-active {
    background: #ffffff url('#{$assets_folder}/jquery.select2/select2-spinner.gif') no-repeat 100%;
}

.select2-container-active .select2-choice,
.select2-container-active .select2-choices {
    border: 1px solid $color_highlight;
    outline: none;
}

.select2-dropdown-open .select2-choice {
    border-bottom-color: transparent;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background-color: #ffffff;
}

.select2-dropdown-open.select2-drop-above .select2-choice,
.select2-dropdown-open.select2-drop-above .select2-choices {
    border: 1px solid $color_highlight;
    border-top-color: transparent;
}

.select2-dropdown-open .select2-choice .select2-arrow {
    background: transparent;
    border-left: none;
    filter: none;
}
.select2-dropdown-open .select2-choice .select2-arrow b {
    background-position: -18px 1px;
}

.select2-hidden-accessible {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

/* results */
.select2-results {
    max-height: 200px;
    padding: 0 0 0 4px;
    margin: 4px 4px 4px 0;
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.select2-results ul.select2-result-sub {
    margin: 0;
    padding-left: 0;
}

.select2-results ul.select2-result-sub > li .select2-result-label { padding-left: 20px }
.select2-results ul.select2-result-sub ul.select2-result-sub > li .select2-result-label { padding-left: 40px }
.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label { padding-left: 60px }
.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label { padding-left: 80px }
.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label { padding-left: 100px }
.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label { padding-left: 110px }
.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label { padding-left: 120px }

.select2-results li {
    list-style: none;
    display: list-item;
    background-image: none;
}

.select2-results li.select2-result-with-children > .select2-result-label {
    font-weight: bold;
}

.select2-results .select2-result-label {
    padding: 3px 7px 4px;
    margin: 0;
    cursor: pointer;
    min-height: 1em;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.select2-results .select2-highlighted {
    background: $color_highlight;
    color: #fff;
}

.select2-results li em {
    background: #feffde;
    font-style: normal;
}

.select2-results .select2-highlighted em {
    background: transparent;
}

.select2-results .select2-highlighted ul {
    background: #ffffff;
    color: #000;
}

.select2-results .select2-no-results,
.select2-results .select2-searching,
.select2-results .select2-selection-limit {
    background: #f4f4f4;
    display: list-item;
    padding-left: 5px;
}

/*
disabled look for disabled choices in the results dropdown
*/
.select2-results .select2-disabled.select2-highlighted {
    color: #666;
    background: #f4f4f4;
    display: list-item;
    cursor: default;
}
.select2-results .select2-disabled {
    background: #f4f4f4;
    display: list-item;
    cursor: default;
}

.select2-results .select2-selected {
    display: none;
}

.select2-more-results.select2-active {
    background: #f4f4f4 url('#{$assets_folder}/jquery.select2/select2-spinner.gif') no-repeat 100%;
}

.select2-more-results {
    background: #f4f4f4;
    display: list-item;
}

/* disabled styles */
.select2-container.select2-container-disabled .select2-choice {
    background-color: #f4f4f4;
    background-image: none;
    border: 1px solid #ddd;
    cursor: default;
}

.select2-container.select2-container-disabled .select2-choice .select2-arrow {
    background-color: #f4f4f4;
    background-image: none;
    border-left: 0;
}

.select2-container.select2-container-disabled .select2-choice abbr {
    display: none;
}


/* multiselect */
.select2-container-multi .select2-choices {
    height: auto !important;
    height: 1%;
    margin: 0;
    padding: 0;
    position: relative;
    border: 1px solid $color_light_secondary;
    border-radius: 4px;
    cursor: text;
    overflow: hidden;
    background-color: #ffffff;
}

.select2-locked {
    padding: 3px 5px 3px 5px !important;
}

.select2-container-multi .select2-choices {
    min-height: 34px;
}

.select2-container-multi.select2-container-active .select2-choices {
    border: 1px solid $color_highlight;
    outline: none;
}

.select2-container-multi .select2-choices li {
    float: left;
    list-style: none;
}

html[dir="rtl"] .select2-container-multi .select2-choices li
{
    float: right;
}

.select2-container-multi .select2-choices .select2-search-field {
    margin: 0;
    padding: 0;
    white-space: nowrap;
}

.select2-container-multi .select2-choices .select2-search-field input {
    padding: 6px 8px 5px;
    margin: 1px 0;
    font-size: 13px;
    color: #999999;
    outline: 0;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    background: transparent !important;
}

.select2-container-multi .select2-choices .select2-search-field input.select2-active {
    background: #fff url('#{$assets_folder}/jquery.select2/select2-spinner.gif') no-repeat 100% !important;
}

.select2-default {
    color: #999 !important;
}

.select2-container-multi .select2-choices .select2-search-choice {
    padding: 4px 18px 4px 5px;
    margin: 6px 0 4px 5px;
    font-weight: 600;
    position: relative;
    font-size: 12px;
    line-height: 13px;
    color: #ffffff;
    cursor: default;
    border-radius: 2px;
    background-clip: padding-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: $color_highlight;
}

html[dir="rtl"] .select2-container-multi .select2-choices .select2-search-choice
{
    margin-left: 0;
    margin-right: 5px;
}
.select2-container-multi .select2-choices .select2-search-choice .select2-chosen {
    cursor: default;
}
.select2-container-multi .select2-choices .select2-search-choice-focus {
    background: #d4d4d4;
}

.select2-search-choice-close {
    position: absolute;
    top: 3px;
    right: 2px;
    display: block;
    color: #ffffff;
    width: 12px;
    height: 12px;
    font-size: 12px;
}

.select2-search-choice-close:before {
    content: "x";
}

.select2-search-choice-close:hover {
    text-decoration: none;
    color: #ffffff;
}

/* disabled styles */
.select2-container-multi.select2-container-disabled .select2-choices {
    background-color: #f4f4f4;
    background-image: none;
    border: 1px solid #ddd;
    cursor: default;
}

.select2-container-multi.select2-container-disabled .select2-choices .select2-search-choice {
    padding: 3px 5px 3px 5px;
    border: 1px solid #ddd;
    background-image: none;
    background-color: #f4f4f4;
}

.select2-container-multi.select2-container-disabled .select2-choices .select2-search-choice .select2-search-choice-close {
    display: none;
    background: none;
}
/* end multiselect */

.select2-result-selectable .select2-match,
.select2-result-unselectable .select2-match {
    text-decoration: underline;
}

.select2-offscreen, .select2-offscreen:focus {
    clip: rect(0 0 0 0) !important;
    width: 1px !important;
    height: 1px !important;
    border: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden !important;
    position: absolute !important;
    outline: 0 !important;
    left: 0px !important;
    top: 0px !important;
}

.select2-display-none {
    display: none;
}

.select2-measure-scrollbar {
    position: absolute;
    top: -10000px;
    left: -10000px;
    width: 100px;
    height: 100px;
    overflow: scroll;
}

/* Bootstrap Style */
.has-warning .select2-container .select2-choice,
.has-warning .select2-container .select2-choices,
.has-warning .select2-container-active .select2-choice,
.has-warning .select2-container-active .select2-choices,
.has-warning .select2-dropdown-open.select2-drop-above .select2-choice,
.has-warning .select2-dropdown-open.select2-drop-above .select2-choices,
.has-warning .select2-container-multi.select2-container-active .select2-choices {
    border: 1px solid #e67e22 !important;
}

.has-warning .select2-container .select2-choice div {
    border-left: 1px solid #e67e22 !important;
}

.has-error .select2-container .select2-choice,
.has-error .select2-container .select2-choices,
.has-error .select2-container-active .select2-choice,
.has-error .select2-container-active .select2-choices,
.has-error .select2-dropdown-open.select2-drop-above .select2-choice,
.has-error .select2-dropdown-open.select2-drop-above .select2-choices,
.has-error .select2-container-multi.select2-container-active .select2-choices {
    border: 1px solid #e74c3c !important;
}

.has-error .select2-container .select2-choice div {
    border-left: 1px solid #e74c3c !important;
}

.has-success .select2-container .select2-choice,
.has-success .select2-container .select2-choices,
.has-success .select2-container-active .select2-choice,
.has-success .select2-container-active .select2-choices,
.has-success .select2-dropdown-open.select2-drop-above .select2-choice,
.has-success .select2-dropdown-open.select2-drop-above .select2-choices,
.has-success .select2-container-multi.select2-container-active .select2-choices {
    border: 1px solid #27ae60 !important;
}

.has-success .select2-container .select2-choice div {
    border-left: 1px solid #27ae60 !important;
}

/* Retina-ize icons */
@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 2dppx)  {
    .select2-search input,
    .select2-search-choice-close,
    .select2-container .select2-choice abbr,
    .select2-container .select2-choice .select2-arrow b {
        background-image: url('#{$assets_folder}/jquery.select2/select2x2.png') !important;
        background-repeat: no-repeat !important;
        background-size: 60px 40px !important;
    }

    .select2-search input {
        background-position: 100% -21px !important;
    }
}