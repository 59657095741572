/*
 *  Slider for Bootstrap
 *
 *  Copyright 2012 Stefan Petre
 *  Licensed under the Apache License v2.0
 *  http://www.apache.org/licenses/LICENSE-2.0
 *
 */

.slider {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    margin: 1px 0;
}

.slider.slider-horizontal {
    width: 100% !important;
    height: 34px;
}

.slider.slider-horizontal .slider-track {
    height: 10px;
    width: 100%;
    margin-top: -5px;
    top: 50%;
    left: 0;
}

.slider.slider-horizontal .slider-selection {
    height: 100%;
    top: 0;
    bottom: 0;
}

.slider.slider-horizontal .slider-handle {
    margin-left: -12px;
    margin-top: -7px;
}

.slider.slider-horizontal .slider-handle.triangle {
    border-width: 0 10px 10px 10px;
    width: 0;
    height: 0;
    border-color: transparent;
    border-bottom-color: #ffffff;
    margin-top: 0;
}

.slider.slider-vertical {
    height: 210px;
    width: 34px;
}

.slider.slider-vertical .slider-track {
    width: 10px;
    height: 100%;
    margin-left: -5px;
    left: 50%;
    top: 0;
}

.slider.slider-vertical .slider-selection {
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
}

.slider.slider-vertical .slider-handle {
    margin-left: -7px;
    margin-top: -12px;
}

.slider.slider-vertical .slider-handle.triangle {
    border-width: 10px 0 10px 10px;
    width: 1px;
    height: 1px;
    border-color: transparent;
    border-left-color: #ffffff;
    margin-left: 0;
}

.slider input {
    display: none;
}

.slider .tooltip-inner {
    white-space: nowrap;
}

.slider-track {
    position: absolute;
    cursor: pointer;
    background-color: $color_light_main;
    border-radius: 4px;
}

.slider-selection {
    position: absolute;
    background-color: $color_highlight;
    border-radius: 4px;
}

.slider-handle {
    position: absolute;
    width: 24px;
    height: 24px;
    background-color: #ffffff;
    border: 1px solid #aaaaaa;
    -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}

.slider-handle.round {
    border-radius: 24px;
}

.slider-handle.triangle {
    background: transparent none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.slider-handle:focus {
    border-color: #333333;
}

.input-slider-danger .slider-selection {
    background-color: #e74c3c;
}

.input-slider-warning .slider-selection {
    background-color: #e67e22;
}

.input-slider-info .slider-selection {
    background-color: #2980b9;
}

.input-slider-success .slider-selection {
    background-color: #27ae60;
}