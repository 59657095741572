.twitter-typeahead {
    width: 100%;
}
.twitter-typeahead .tt-input,
.twitter-typeahead .tt-hint {
    width: 100%;
    margin: 0px;
    padding: 8px 12px;
}
.twitter-typeahead .tt-input:focus {
    width: 100%;
}
.twitter-typeahead .tt-hint {
    color: #999;
}
.twitter-typeahead .tt-dropdown-menu {
    width: 100%;
    padding: 0;
    background-color: #FFFFFF;
    border-top: 0px;
    z-index: 2000 !important;
    -webkit-box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.25);
    -moz-box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.25);
    box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.25);
    cursor: pointer;
}
.twitter-typeahead .tt-dropdown-menu .tt-suggestion {
    text-align: left;
    padding: 3px 20px;
    font-size: 18px;
    line-height: 24px;
}
.twitter-typeahead .tt-dropdown-menu .tt-suggestion.tt-cursor {
    color: #fff; background-color: $color_highlight;
}
.twitter-typeahead .tt-dropdown-menu .tt-suggestion em {
    font-weight: bold;
    font-style: normal;
}