/*
 *  Document   : variables
 *  Author     : bhWebDesigner
 *  Description: Variables for changing color
 *
 */

/*
 * Color Theme
 *
 */

/* Main Colors */
$color_dark:                    #232824;                    // Main dark color - Used mainly for sidebars' background and text color 394263
$color_highlight:               #7dc419;                    // Main highlight color - Used for highlighting and as a primary color
$color_light_main:              #d8d8d8;                    // Main light color - Used mainly for main content background
$color_light_secondary:         #c6cbbf;                    // Secondary light color - Used mainly for borders
$color_light_ultra:             #fafcfa;                    // Secondary ultra light color - Used mainly for light navbar and block's header background

$erac_color:                    #009750;                    // ERAC Color
$qbps_color:                    #002e5b;                    // QBPS Color

/* Navbar Inverse Color */
$color_navbar_inverse:          #232824;                    // Dark navbar color

/* Default Button */
$color_btn_default:             $color_light_main;          // Default color
$color_btn_default_b:           $color_light_secondary;     // Default border color
$color_btn_default_bg:          #f1f4ed;                    // Default background color
$color_btn_default_bh:          #d3e0c1;                    // Hover border color
$color_btn_default_ac:          $color_light_main;          // Active background and border color

/* Primary Button */
$color_btn_primary:             $color_highlight;           // Default color
$color_btn_primary_bg:          #8bc13e;                    // Default background color
$color_btn_primary_bh:          #689726;                    // Hover border color

/*
 * Various Options
 *
 */

$assets_folder:                 '../img';                   // Assets folder - Useful if you moved the folder elsewhere

$radius_blocks:                 0;                          // Roundness of blocks in pixels - eg: 5px

$width_sidebar:                 200px;                      // Width of both sidebars - Suggested values between 180px and 220px
$width_sidebar_partial:         65px;                       // Width of both sidebars when partial - Suggested values between 30px and 100px