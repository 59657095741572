/*
 *  Document   : plugins
 *  Author     : bhWebDesigner
 *  Description: Plugins file
 *
 */

/* Template Variables */
@import "variables";

/* Font Icons (minified) */
@import "plugins/font_awesome";
@import "plugins/glyphicons";

/* Plugins */
@import "plugins/full_calendar";
@import "plugins/dropzone";
@import "plugins/chosen";
@import "plugins/datepicker";
@import "plugins/bootstrap_colorpicker";
@import "plugins/prism";
@import "plugins/magnific_popup";
@import "plugins/datatables";
@import "plugins/easy_pie_chart";
@import "plugins/css3_animations";
@import "plugins/timepicker";
@import "plugins/tags_input";
@import "plugins/slider";
@import "plugins/nprogress";
@import "plugins/select2";
@import "plugins/sweetalert";
@import "plugins/algolia";
@import "plugins/ladda";
@import "plugins/typeahead";