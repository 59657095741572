#stats {
    p {
        margin-bottom: 15px;
        .stats {
            color: $color_dark;
            background-color: $color_light_ultra;
            border: 1px solid $color_light_main;
            border-radius: 5px;
            padding: 7px 12px;
            display: block;
            text-transform: uppercase;
            text-align: center;
        }
    }
}