/*
 *  Timepicker Component for Twitter Bootstrap
 *
 *  Copyright 2013 Joris de Wit
 *  Contributors https://github.com/jdewit/bootstrap-timepicker/graphs/contributors
 *
 */

.bootstrap-timepicker {
    position: relative;
}
.bootstrap-timepicker input {
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
}
.bootstrap-timepicker .input-group-addon {
    cursor: pointer;
}
.bootstrap-timepicker.pull-right .bootstrap-timepicker-widget.dropdown-menu {
    left: auto;
    right: 0;
}
.bootstrap-timepicker.pull-right .bootstrap-timepicker-widget.dropdown-menu:before {
    left: auto;
    right: 12px;
}
.bootstrap-timepicker.pull-right .bootstrap-timepicker-widget.dropdown-menu:after {
    left: auto;
    right: 13px;
}
.bootstrap-timepicker-widget.dropdown-menu {
    padding: 2px 3px 2px 2px;
}
.bootstrap-timepicker-widget.dropdown-menu.open {
    display: inline-block;
}
.bootstrap-timepicker-widget.dropdown-menu:before {
    border-bottom: 7px solid rgba(0, 0, 0, 0.2);
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    content: "";
    display: inline-block;
    left: 9px;
    position: absolute;
    top: -7px;
}
.bootstrap-timepicker-widget.dropdown-menu:after {
    border-bottom: 6px solid #FFFFFF;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    content: "";
    display: inline-block;
    left: 10px;
    position: absolute;
    top: -6px;
}
.bootstrap-timepicker-widget a.btn,
.bootstrap-timepicker-widget input {
    border-radius: 4px;
}
.bootstrap-timepicker-widget table {
    width: 100%;
    margin: 0;
}
.bootstrap-timepicker-widget table td {
    text-align: center;
    height: 30px;
    margin: 0;
    padding: 2px;
}
.bootstrap-timepicker-widget table td:not(.separator) {
    min-width: 30px;
}
.bootstrap-timepicker-widget table td span {
    width: 100%;
}
.bootstrap-timepicker-widget table td a {
    width: 100%;
    display: inline-block;
    margin: 0;
    padding: 8px 0;
    outline: 0;
    color: #000;
    border-radius: 3px;
}
.bootstrap-timepicker-widget table td a:hover {
    text-decoration: none;
    background-color: $color_highlight;
    color: #ffffff;
}
.bootstrap-timepicker-widget table td a i {
    margin-top: 2px;
    font-size: 18px;
}
.bootstrap-timepicker-widget table td input {
    width: 25px;
    margin: 0;
    text-align: center;
}
.bootstrap-timepicker-widget .modal-content {
    padding: 4px;
}
@media (min-width: 767px) {
    .bootstrap-timepicker-widget.modal {
        width: 200px;
        margin-left: -100px;
    }
}
@media (max-width: 767px) {
    .bootstrap-timepicker {
        width: 100%;
    }
    .bootstrap-timepicker .dropdown-menu {
        width: 100%;
    }
}